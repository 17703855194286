import React, { useState } from "react"
import Header from "./header"
import { SearchProvider } from "./context"

const Layout = ({ children }) => {
  const [searchResults, setSearchResults] = useState([])
  const [fireSubmit, setFireSubmit] = useState(false)
  const [search, setSearch] = useState("")
  const state = {
    searchResults: searchResults,
    setSearchResults: setSearchResults,
    fireSubmit: fireSubmit,
    setFireSubmit: setFireSubmit,
    search: search,
    setSearch: setSearch,
  }
  return (
    <SearchProvider value={state}>
      <Header setSearch={setSearch} setFireSubmit={setFireSubmit} />
      <main>{children}</main>
    </SearchProvider>
  )
}

export default Layout
