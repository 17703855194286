// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-specialists-js": () => import("./../../../src/pages/specialists.js" /* webpackChunkName: "component---src-pages-specialists-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/home-template.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-specialist-template-js": () => import("./../../../src/templates/specialistTemplate.js" /* webpackChunkName: "component---src-templates-specialist-template-js" */),
  "component---src-templates-wp-blog-template-js": () => import("./../../../src/templates/wpBlogTemplate.js" /* webpackChunkName: "component---src-templates-wp-blog-template-js" */)
}

