import React from "react"
import Layout from './layout'
import PropTypes from "prop-types"

export const wrapRootElement = ({ element }) => (
  <Layout>
    {element}
  </Layout>
)
wrapRootElement.propTypes = {
  element: PropTypes.object,
}
