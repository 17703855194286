import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Footer from "./footer"
import styles from "./sidebar.module.scss"

export default function SearhBar({ setSearch, setFireSubmit, handleClose }) {
  const data = useStaticQuery(graphql`
    query {
      allWordpressTag {
        edges {
          node {
            name
            count
          }
        }
      }
    }
  `)
  const tags = data.allWordpressTag.edges.sort(function(a, b) {
    return b.node.count - a.node.count
  })
  const doit = () => (handleClose ? handleClose() : "")
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>Categorías principales</h2>
      <ul className={styles.tagList}>
        {tags.map((tag, id) =>
          tag.node.count > 0 && id < 8 ? (
            <li className={styles.tag}>
              <button
                value={tag.node.name}
                onClick={e => {
                  setSearch(e.target.value)
                  setFireSubmit(true)
                  doit()
                }}
                className={styles.button}
              >
                {tag.node.name}
              </button>
            </li>
          ) : (
            ""
          )
        )}
      </ul>
      <Footer />
    </div>
  )
}
