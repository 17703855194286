import React from "react"
import headerStyles from "./header.module.scss"
import Logo from "../images/logo.png"
import { Link } from "gatsby"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Fade from "@material-ui/core/Fade"
import SideBar from "./sideBar"

const Header = ({ setSearch, setFireSubmit }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }
  return (
    <header className={headerStyles.container}>
      <Link to="/" title="Home" className={headerStyles.logoContainer}>
        <div>
          <img className={headerStyles.logo} src={Logo} alt="Logo unio" />
        </div>
        <h1 className={headerStyles.pageName}>
          Union
          <br />
          <span style={{ fontSize: ".6em" }}>by Unio</span>
        </h1>
      </Link>
      <nav className={headerStyles.nav}>
        <a
          href="http://uniomx.com/?utm_source=unionunio.com"
          title="Registro"
          rel="noreferrer noopener"
          className={headerStyles.linkItem}
        >
          Descubre Unio
        </a>
        <a
          href="https://admin.unionunio.com/?utm_source=unionunio.com"
          title="Registro"
          rel="noreferrer noopener"
          className={headerStyles.linkItem}
        >
          Registrarse
        </a>
        <a
          href="https://admin.unionunio.com/wp-admin/?utm_source=unionunio.com"
          title="Registro"
          rel="noreferrer noopener"
          className={headerStyles.linkItem}
        >
          Iniciar Sesión
        </a>
      </nav>
      <nav className={headerStyles.mobileNav}>
        <Button
          className={headerStyles.linkButton}
          aria-controls="fade-menu"
          style={{ color: "#0E7CFF", fontSize: "1.2em" }}
          aria-haspopup="true"
          onClick={handleClick}
        >
          Menú
        </Button>
        <Menu
          classes={{
            paper: headerStyles.menu,
            list: headerStyles.menuList,
          }}
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <MenuItem className={headerStyles.menuItem} onClick={handleClose}>
            <a
              className={headerStyles.link}
              activeClassName={headerStyles.activeLink}
              partiallyActive={true}
              title="Qué es Unio"
              target="__blank"
              rel="noopener noreferrer"
              href="http://uniomx.com/?utm_source=unionunio.com"
            >
              <span
                className={headerStyles.navItem}
                style={{ color: "#0E7CFF" }}
                key="descubreUnio"
              >
                Descubre Unio
              </span>
            </a>
          </MenuItem>
          <MenuItem className={headerStyles.menuItem} onClick={handleClose}>
            <a
              className={headerStyles.link}
              activeClassName={headerStyles.activeLink}
              partiallyActive={true}
              title="Iniciar Sesión"
              target="__blank"
              rel="noopener noreferrer"
              href="https://admin.unionunio.com/wp-admin/?utm_source=unionunio.com"
            >
              <span
                className={headerStyles.navItem}
                style={{ color: "#0E7CFF" }}
                key="descubreUnio"
              >
                Iniciar Sesión
              </span>
            </a>
          </MenuItem>
          <MenuItem className={headerStyles.menuItem} onClick={handleClose}>
            <a
              className={headerStyles.link}
              activeClassName={headerStyles.activeLink}
              partiallyActive={true}
              title="Iniciar Sesión"
              target="__blank"
              rel="noopener noreferrer"
              href="https://admin.unionunio.com/?utm_source=unionunio.com"
            >
              <span
                className={headerStyles.navItem}
                style={{ color: "#0E7CFF" }}
                key="descubreUnio"
              >
                Registrarse
              </span>
            </a>
          </MenuItem>
          <SideBar
            handleClose={handleClose}
            setSearch={setSearch}
            setFireSubmit={setFireSubmit}
          />
        </Menu>
      </nav>
    </header>
  )
}

export default Header
