import React from "react"
import styles from "./footer.module.scss"
import { Link } from "gatsby"


export default function Footer() {
  return (
    <div className={styles.container}>
      <Link className={styles.tyc} to="/specialists">
        <h2 className={styles.link}>
          ¿Preguntas?
          <br />
          Un especialista puede resolverlas
        </h2>
      </Link>
      <Link
        className={styles.tyc}
        title="Términos y condiciones"
        to="terminos-y-condiciones"
      >
        Términos y condiciones
      </Link>
    </div>
  )
}
